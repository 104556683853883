/* Ember Column Conform - V1.1 */
/*
            Usage:

            colsTrips = $('.bl-3-col-trips .trip-item').colConform({
                innerItems: ['.bl-3-col-header', '.bl-3-col-content', 'box']
            });
*/

(function ( $ ) {
 
    $.fn.colConform = function( options ) {

        // This is the easiest way to have default options.
        var settings = $.extend({
            // These are the defaults.
            innerItems: ['box'], // default to resizing the outer box
            singleRow: false  // Override rows (eg if col items are in horizontal carousel)
        }, options );

        var me = this,
            gridArray = [];

        // private methods

        var setGrid = function() {
            // Create an array of objects based on the rows

            var topPosition = 0,
                currentRowStart = 0,
                currentRow = 0;

            me.each(function(i){

                $el = $(this);
                topPostion = $el.position().top;

                // don't want to fire setheights() on the first block
                if (i==0){
                    currentRowStart = topPostion;
                    gridArray.push([]);
                }

               
                if(!settings.singleRow){

                    if (currentRowStart != topPostion) {
                        //
                        // we just came to a new row.
                        currentRow ++;
                        gridArray.push([]);

                        // Reset top height to account for resized blocks in prev row
                        topPostion = $el.position().top;
                        currentRowStart = topPostion;
                    }

                }   

                //add element to array
                gridArray[currentRow].push($el);

            });

            setHeights();
        }

        var setHeights = function() {

            var tallestHeight = 0;

            for(i=0; i<=settings.innerItems.length-1; i++){
            // each DOM element in array
                for(j=0; j<=gridArray.length-1; j++){
                // each row
                    for(k=0; k<=gridArray[j].length-1; k++){
                    // each item in row

                        // work out what the tallest height(s) are
                        if(k==0){
                            if(settings.innerItems[i]=='box'){
                                // Get height of container box
                                tallestHeight = gridArray[j][k].height();
                            }else{
                                // Get height of element inside box
                                tallestHeight = gridArray[j][k].find(settings.innerItems[i]).height();
                            }
                        }else{
                            if(settings.innerItems[i]=='box'){
                                // Use height of container box
                                tallestHeight = (tallestHeight < gridArray[j][k].height()) ? (gridArray[j][k].height()) : (tallestHeight);
                            }else{
                                // Use height of element inside box
                                tallestHeight = (tallestHeight < gridArray[j][k].find(settings.innerItems[i]).height()) ? (gridArray[j][k].find(settings.innerItems[i]).height()) : (tallestHeight);
                            }


                        }

                    }

                    for(k=0; k<=gridArray[j].length-1; k++){
                        // apply tallest height to each item in row
                        if(settings.innerItems[i]=='box'){
                            // Add height to container box
                            gridArray[j][k].height(tallestHeight);
                        }else{
                            // Add height to element inside box
                            gridArray[j][k].find(settings.innerItems[i]).height(tallestHeight);
                        }
                    }

                }
            }
        }

        var clearHeights = function() {

            me.each(function(i){
                
                var thisItem = $(this);
                // Reset all heights
                //thisItem.removeAttr('style');// was interfering with other inline styles
                thisItem.css({height:'auto'});
                //
                for(i=0; i<=settings.innerItems.length-1; i++){
                    if(settings.innerItems[i]!='box'){
                        ///thisItem.find(settings.innerItems[i]).removeAttr('style');// was interfering with other inline styles
                        thisItem.find(settings.innerItems[i]).css({height:'auto'});
                    }
                }

            });

        }

        // public methods

        this.resetHeights = function() {

            // remove heights
            clearHeights();

            // reset variables
            gridArray.length = 0;

            // start again
            setGrid();
            
        };

        this.destroy = function() {
           
            // remove heights
            clearHeights();

            // stop window resize events
            $(window).off( "debouncedresize" );
        };
 
     
        this.initialize = function() {

            $(window).on("debouncedresize", function( event ) {
                me.resetHeights();
            });
           
            setGrid();

            return this;
        };

        return this.initialize();
 
    };
 
}( jQuery ));
